import type { ReactNode } from 'react';

import { createContext, useContext, useEffect, useMemo } from 'react';
import { useSelector } from '@xstate/react';
import { useNetworkState } from 'react-use';

import { service as offlineService } from '@/stores/xstate/offline/offlineMachine';
import { syncStatusService } from '@/stores/xstate/sync/syncStatusMachine';

type OfflineContextProps = {
	service: any;
	syncService: any;
	state: any;
};

const OfflineStatusContext = createContext<OfflineContextProps | undefined>( undefined );

function selectStatus( state: any ) {
	return {
		isOnline: state.context.isOnline,
		lastChecked: state.context.lastChecked
	};
}

function OfflineStatusProvider({ children }: { children: ReactNode }) {
	const state = useSelector( offlineService, selectStatus );

	const networkState = useNetworkState();

	useEffect( () => {
		if ( networkState.online === networkState.previous ) {
			return;
		}

		offlineService.send( networkState.online ? 'CONNECTION_ACTIVE' : 'CONNECTION_INACTIVE' );
	}, [ networkState.online, networkState.previous ] );

	const returnValue = useMemo( () => ({
		service: offlineService,
		syncService: syncStatusService,
		state
	}), [ offlineService, state ] );

	return (
		<OfflineStatusContext.Provider value={ returnValue }>
			{ children }
		</OfflineStatusContext.Provider>
	);
}

function useOfflineStatus() {
	const context = useContext( OfflineStatusContext );

	if ( context === undefined ) {
		throw new Error( 'useOfflineStatus must be used within an OfflineStatusProvider' );
	}

	return context;
}

export { OfflineStatusProvider, useOfflineStatus };
