import type { CollectionExport } from '@/types/global';

type ApplicationSettings = {
	settingsId: string;
	ccfApplicationSettings: string;
	testReasons: string;
	hairLocationLookupValues: string;
	anonymousTestLookupValues: string;
	languageLookupValues: string;
	yesNoLookupValues: string;
	testSubprocessResultLookupValues: string;
	donorSexLookupValues: string;
	hairTypeLookupValues: string;
	sampleTypes: string;
	splitTypeValues: string;
	overallResultTypes: string;
	typesOfTest: string;
	testingAuthorities: string;
};

export const applicationSettings: CollectionExport<ApplicationSettings> = {
	schema: {
		title: 'applicationsettings',
		version: 5,
		primaryKey: 'settingsId',
		type: 'object',
		properties: {
			settingsId: {
				type: 'string',
				maxLength: 5
			},
			ccfApplicationSettings: {
				type: 'string'
			},
			testReasons: {
				type: 'string'
			},
			hairLocationLookupValues: {
				type: 'string'
			},
			anonymousTestLookupValues: {
				type: 'string'
			},
			languageLookupValues: {
				type: 'string'
			},
			yesNoLookupValues: {
				type: 'string'
			},
			testSubprocessResultLookupValues: {
				type: 'string'
			},
			donorSexLookupValues: {
				type: 'string'
			},
			hairTypeLookupValues: {
				type: 'string'
			},
			sampleTypes: {
				type: 'string'
			},
			splitTypeValues: {
				type: 'string'
			},
			overallResultTypes: {
				type: 'string'
			},
			typesOfTest: {
				type: 'string'
			},
			testingAuthorities: {
				type: 'string'
			}
		}
	},
	migrationStrategies: {
		4: function( oldDoc ) {
			if ( !oldDoc.typesOfTest ) {
				oldDoc.typesOfTest = '[]';
			}

			if ( !oldDoc.testingAuthorities ) {
				oldDoc.testingAuthorities = '[]';
			}

			return oldDoc;
		},
		5: function( oldDoc ) {
			if ( !oldDoc.splitTypeValues ) {
				oldDoc.splitTypeValues = '[]';
			}

			return oldDoc;
		}
	}
};
