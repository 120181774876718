import { assign, createMachine, interpret } from 'xstate';

import { getCurrentDateString } from '@/services/date';
import { setStorageItem } from '@/stores/localstorage';
import { syncRecords } from '@/stores/xstate/sync/syncRecords';

type ErrorMessage = {
	collectionId: string;
	error: string;
};

export interface SyncStatusContext {
	lastUpdated: string,
	isSyncEnabled: boolean,
	isSyncing: boolean,
	errorMessages: ErrorMessage[]
}

type SyncStatusEvents =
	| { type: 'ENABLE_SYNC' }
	| { type: 'DISABLE_SYNC' }
	| { type: 'START_SYNC' }
	| { type: 'ADD_ERROR', message: ErrorMessage }
	| { type: 'REMOVE_ERROR', message: string }
	| { type: 'CLEAR_ERRORS' };

type SyncStatusTypestate =
	| { value: 'idle', context: SyncStatusContext }
	| { value: 'waiting', context: SyncStatusContext }
	| { value: 'syncing', context: SyncStatusContext };

export const syncStatusMachine = createMachine<SyncStatusContext, SyncStatusEvents, SyncStatusTypestate>({
	id: 'ccfSyncStatus',
	initial: 'idle',
	context: {
		lastUpdated: '',
		isSyncEnabled: true,
		isSyncing: false,
		errorMessages: []
	},
	states: {
		idle: {
			id: 'idle',
			entry: [ 'setIsNotSyncing' ],
			on: {
				ENABLE_SYNC: {
					target: 'waiting'
				},
				START_SYNC: {
					target: 'syncing'
				},
				ADD_ERROR: {
					target: 'idle',
					actions: [ 'setErrors' ]
				},
				REMOVE_ERROR: {
					actions: [ 'removeError' ]
				},
				CLEAR_ERRORS: {
					actions: [ 'clearErrors' ]
				}
			}
		},
		waiting: {
			id: 'waiting',
			entry: [ 'setIsNotSyncing' ],
			on: {
				DISABLE_SYNC: {
					target: 'idle'
				}
			},
			after: {
				SYNC_FREQUENCY: { target: 'syncing' }
			}
		},
		syncing: {
			entry: [ 'setIsSyncing' ],
			invoke: {
				id: 'startingSync',
				src: 'syncRecords',
				onDone: [
					{
						target: 'waiting',
						cond: 'isSyncEnabled',
						actions: [ 'updateTimestamp' ]
					},
					{
						target: 'idle',
						actions: [ 'updateTimestamp' ]
					}
				],
				onError: {
					target: 'idle'
				}
			}
		}
	}
}, {
	actions: {
		setErrors: assign( ( context, event: any ) => {
			const matchedErrorsIndex = context.errorMessages.findIndex( a => a.collectionId === event.message.collectionId );
			const errors = [ ...context.errorMessages ];

			if ( matchedErrorsIndex >= 0 ) {
				errors[ matchedErrorsIndex ] = event.message;
			} else {
				errors.push( event.message );
			}

			return {
				...context,
				errorMessages: errors
			};
		}),
		removeError: assign(( context, event: any ) => {
			return {
				...context,
				errorMessages: [ ...context.errorMessages ].filter( a => a.collectionId !== event.message )
			};
		}),
		clearErrors: assign(( context: SyncStatusContext ) => {
			return {
				...context,
				errorMessages: []
			}
		}),
		setIsSyncing: assign( context => ({
			...context,
			isSyncing: true
		})),
		setIsNotSyncing: assign( context => ({
			...context,
			isNotSyncing: true
		})),
		updateTimestamp: assign( context => {
			const newTimestamp = getCurrentDateString();

			setStorageItem( 'synctimestamp', newTimestamp );

			return {
				...context,
				lastUpdated: newTimestamp
			};
		})
	},
	services: {
		syncRecords
	},
	guards: {
		isSyncEnabled: ( context ) => context.isSyncEnabled
	},
	delays: {
		SYNC_FREQUENCY: 5000
	}
});

export const syncStatusService = interpret( syncStatusMachine ).start();
