import { getSession } from 'next-auth/react';

import { checkHeartbeat } from '@/services/connectionStatus';

export const API_BASE = process.env.NEXT_PUBLIC_API_BASE;

async function precheck( WrappedFunction: Function ) {
	try {
		await checkHeartbeat();
		
		return WrappedFunction();
	} catch( e ) {
		return Promise.reject( e );
	}
}

export async function getRequestingAgency( agencyId: string ) {
	return fetch( `/api/getRequestingAgency?requestingAgencyId=${ agencyId }` ).then( res => res.json() );
}

export async function getRequestingAgencies( collectionSiteId: string ) {
	return fetch( `/api/getRequestingAgencies?collectionSiteId=${ collectionSiteId }` ).then( res => res.json() );
}

export async function getTranslations() {
	const translationRequest = await fetch( `${ API_BASE }/v2/translations` ).then( res => res.json() );

	return translationRequest.languageTranslations;
}

export async function getCollectionSiteDetails() {
	const session: any = await getSession();

	if ( !session?.user?.collectionSiteClientId ) {
		throw new Error( 'No site ID is available' );
	}

	const updateBody = {
		isMobileAppUser: true,
		collectionSiteClientId: session.user.collectionSiteClientId
	};

	const collectionSiteCall = await fetch( '/api/getCollectionSiteDetails', {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify( updateBody )
	}).then( res => res.json() );

	return collectionSiteCall.collectionSiteSyncData;
}

export async function getApplicationSettings() {
	const settingsCall = await fetch( `${ API_BASE }/datasync/settings`, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		}
	}).then( res => res.json() );

	return settingsCall.applicationSettingSyncData;
}

export async function getUserAccountDetails( userId: string ) {
	return fetch( `/api/getUserAccountDetails?userId=${ userId }` ).then( res => res.json() );
}

export async function updateUserAccountDetails( userId: string, userObj: any ) {
	const updateBody = {
		...userObj,
		userId
	};

	return fetch( '/api/updateUserAccountDetails', {
		method: 'put',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify( updateBody )
	}).then( res => res.json() );
}

export async function updateUserPin( pinUpdateObj: any ) {
	return fetch( '/api/updateUserPin', {
		method: 'put',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify( pinUpdateObj )
	}).then( res => res.json() );
}

export type CollectionRequestObj = {
	searchTerm: string;
	startDate: string | null;
	endDate: string | null;
	showCompleted: boolean;
	isIncludeCollectionConsent: boolean;
	isClearCollectionSites?: boolean;
	pageSize: number;
	page: number;
	collectionSiteId?: string;
	userId?: string;
};

export type RecentCollectionRequestObj = CollectionRequestObj & {
	lastSyncedDate: string;
};

export async function getCollections( collectionObj: CollectionRequestObj ): Promise<any | undefined> {
	return precheck( () => {
		return fetch( '/api/getCollections', {
			method: 'post',
			body: JSON.stringify( collectionObj ),
			headers: {
				'Content-Type': 'application/json'
			}
		}).then( res => res.json() );
	});
}

export async function getRecentCollections( collectionObj: RecentCollectionRequestObj ): Promise<any | undefined> {
	return precheck( () => {
		return fetch( '/api/getRecentCollections', {
			method: 'post',
			body: JSON.stringify( collectionObj ),
			headers: {
				'Content-Type': 'application/json'
			}
		}).then( res => res.json() );
	});
}

export async function getTestRequest( testRequestId: string ) {
	const testRequest = await fetch( `/api/getTestRequest?testRequestId=${ testRequestId }` );

	if ( testRequest.status === 404 ) {
		return null;
	} else {
		return testRequest.json();
	}
}

export async function getCollection( collectionId: string ) {
	return fetch( `/api/getCollection?collectionId=${ collectionId }` ).then( res => res.json() );
}

export async function saveCollection( collectionObj: any ) {
	return fetch( '/api/saveCollection', {
		method: 'post',
		body: JSON.stringify( collectionObj ),
		headers: {
			'Content-Type': 'application/json'
		}
	}).then( res => res.json() );
}

export async function deleteCollection( collectionId: any ) {
	return fetch( `/api/deleteCollection?collectionId=${ collectionId }` ).then( res => res.json() );
}

export async function forgotPassword( username: string ) {
	return fetch( `${ API_BASE }/accountsettings/resetpassword?username=${ encodeURIComponent( username ) }`, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		}
	});
}

export async function forgotCollectionSite( username: string ) {
	return fetch( `${ API_BASE }/accountsettings/v2/accountsettings/collectionsites`, {
		method: 'post',
		body: JSON.stringify({ username }),
		headers: {
			'Content-Type': 'application/json'
		}
	});
}

export async function getStateToken( collectionSiteId: string ) {
	return fetch( `${ API_BASE }/initialize/login`, {
		method: 'post',
		body: JSON.stringify({ collectionSiteId }),
		headers: {
			'Content-Type': 'application/json'
		}
	}).then( res => res.json() );
}

export async function getUsers( searchTerm = '', showInactive = false ) {
	return fetch( `/api/getUsers?searchTerm=${ encodeURIComponent( searchTerm ) }&showInactive=${ showInactive }` )
		.then( res => res.json() );
}

export async function getUser( userId: string ) {
	return fetch( `/api/getUser?userId=${ encodeURIComponent( userId ) }` )
		.then( res => res.json() );
}

export async function updateUser( userId: string, userObj: any ) {
	return fetch( `/api/updateUser`, {
		method: 'put',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			...userObj,
			userId
		})
	});
}

export async function createUser( userObj: any ) {
	return fetch( `/api/updateUser`, {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			...userObj
		})
	});
}

export async function activateUser( invitationId: string, collectionSiteId: string, email: string ) {
	return fetch( `${ API_BASE }/firsttimeuser/activatefirsttimeuser`, {
		method: 'POST',
		body: JSON.stringify({
			invitationId,
			collectionSiteId,
			email,
			captcha: ''
		}),
		headers: {
			'Content-Type': 'application/json'
		}
	}).then( res => res.json() );
}

export async function changePassword( token: string, newPassword: string, newPasswordConfirm: string ) {
	const searchParams = new URLSearchParams({
		tk: token,
		np: newPassword,
		npc: newPasswordConfirm
	});

	return fetch( `${ API_BASE }/accountsettings/changepassword?${ searchParams }`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		}
	}).then( res => res.json() );
}

export async function updateSessionPin( pinHash: string ) {
	return fetch( `/api/auth/session?pinHash=${ pinHash }` );
}
