import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTriangleExclamation,
	faAngleRight,
	faAngleLeft,
	faBars,
	faRotate,
	faSlash,
	faSortDown,
	faSortUp,
	faTrash,
	faSquareCheck,
	faSquareXmark,
	faArrowDown
} from '@fortawesome/free-solid-svg-icons';

export type IconGlyph = 'trash' | 'burger' | 'sortDown' | 'sortUp' | 'arrowDown' | 'arrowRight' | 'arrowLeft' | 'activeSpinner' | 'inactiveSpinner' | 'spinner' | 'squareCheck' | 'squareX' | 'warning';
export type IconSize = 'small' | 'medium' | 'large';

export type IconProps = {
	glyph: IconGlyph;
	color?: string;
	className?: string;
	size?: IconSize;
};

const IconMap: Record<IconGlyph, Function> = {
	warning: Warning,
	trash: Trash,
	burger: Burger,
	sortDown: SortDown,
	sortUp: SortUp,
	arrowDown: ArrowDown,
	arrowRight: ArrowRight,
	arrowLeft: ArrowLeft,
	activeSpinner: ActiveSpinner,
	inactiveSpinner: InactiveSpinner,
	spinner: Spinner,
	squareCheck: SquareCheck,
	squareX: SquareX
};

const SizeMap: Record<IconSize, string> = {
	small: 'max-w-[24px] icon--small',
	medium: 'max-w-[32px] icon--medium',
	large: 'max-w-[44px] icon--large'
};

function SquareX() {
	return <FontAwesomeIcon icon={ faSquareXmark } color="#dddddd" />;
}

function SquareCheck({ color = '#676767' }: { color?: string }) {
	return <FontAwesomeIcon icon={ faSquareCheck } color={ color } />;
}

function Trash() {
	return <FontAwesomeIcon icon={ faTrash } color="#676767" />;
}

function Burger() {
	return <FontAwesomeIcon icon={ faBars } color="#387ef5" size="1x" />;
}

function SortDown() {
	return <FontAwesomeIcon icon={ faSortDown } color="#444" size="1x" />;
}

function SortUp() {
	return <FontAwesomeIcon icon={ faSortUp } color="#444" size="1x" />;
}

function ArrowDown() {
	return <FontAwesomeIcon icon={ faArrowDown } color="#444" />;
}

function ArrowRight() {
	return <FontAwesomeIcon icon={ faAngleRight } color="#444" />;
}

function ArrowLeft() {
	return <FontAwesomeIcon icon={ faAngleLeft } color="#444" />;
}

function Spinner({ color = '#777' }: { color?: string }) {
	return <FontAwesomeIcon icon={ faRotate } color={ color } />;
}

function Warning() {
	return <FontAwesomeIcon icon={ faTriangleExclamation } color="#b22e2e" />;
}

function InactiveSpinner() {
	return (
		<span>
			<span className="absolute top-0 left-0.5">
				<FontAwesomeIcon icon={ faRotate } color="#333" />
			</span>

			<span className="absolute top-0 left-0">
				<FontAwesomeIcon icon={ faSlash } color="#b22e2e" />
			</span>
		</span>
	);
}

function ActiveSpinner() {
	return (
		<span>
			<span className="absolute top-0 left-0.5">
				<FontAwesomeIcon icon={ faRotate } color="#333" />
			</span>
		</span>
	);
}

export default function Icon({ glyph, color, className, size = 'small'} : IconProps ) {
	const DisplayedIcon = IconMap[ glyph ];
	const sizeString = SizeMap[ size ];

	return (
		<div className={ `inline-block ${ sizeString } ${ className }` }>
			<DisplayedIcon color={ color } />
		</div>
	);
}
