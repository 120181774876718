import type { PropsWithChildren } from 'react';

import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';

type ActiveModal = {
	modalId: string;
	modalText: string;
};

type LoadingProviderProps = PropsWithChildren;

type LoadingContextProps = {
	openModal: ( modalObj: ActiveModal ) => void;
	closeModal: ( modalId: string ) => void;
	currentModal: ActiveModal | null;
	isModalOpen: boolean;
} | undefined;

type ModalsReducerState = {
	modals: ActiveModal[];
};

type ModalsActions =
	| { type: 'add_modal', payload: ActiveModal }
	| { type: 'remove_modal', payload: string }
	| { type: 'remove_most_recent' };

function modalsReducer( state: ModalsReducerState, action: ModalsActions ): ModalsReducerState {
	if ( action.type === 'add_modal' ) {
		const existingItemIndex = state.modals.findIndex( ( modal: ActiveModal ) => modal.modalId === action.payload.modalId );

		if ( existingItemIndex >= 0 ) {
			const duplicateState = [ ...state.modals ];

			duplicateState[ existingItemIndex ].modalText = action.payload.modalText;

			return {
				modals: duplicateState
			};
		}

		return {
			modals: [ ...state.modals, { ...action.payload } ]
		};
	}

	if ( action.type === 'remove_modal' ) {
		const itemToRemove = action.payload;
		const matchedItem = state.modals.findIndex( ( modal: ActiveModal ) => modal.modalId === itemToRemove );

		let modalsCopy = [ ...state.modals ];

		if ( matchedItem >= 0 ) {
			modalsCopy.splice( matchedItem, 1 );
		}

		return {
			...state,
			modals: modalsCopy
		};
	}

	if ( action.type === 'remove_most_recent' ) {
		let modalsCopy = [ ...state.modals ];

		modalsCopy.pop();

		return {
			...state,
			modals: modalsCopy
		};
	}

	throw Error( 'Unknown action given to modal reducer' );
}

const LoadingContext = createContext<LoadingContextProps>( undefined );

function LoadingProvider({ children }: LoadingProviderProps ) {
	const [ loadingModals, dispatchModal ] = useReducer( modalsReducer, { modals: [] } );

	return (
		<LoadingContext.Provider value={{
			currentModal: useMemo( () => {
				if ( loadingModals.modals.length === 0 ) {
					return null;
				}

				return loadingModals.modals[ 0 ];
			}, [ loadingModals.modals ] ),

			isModalOpen: useMemo( () => {
				return loadingModals.modals.length > 0;
			}, [ loadingModals.modals ] ),

			openModal: useCallback( ( modalObj: ActiveModal ) => {
				dispatchModal({ type: 'add_modal', payload: modalObj });
			}, [ dispatchModal ] ),

			closeModal: useCallback( ( modalId: string ) => {
				dispatchModal({ type: 'remove_modal', payload: modalId });
			}, [ dispatchModal ] )
		}}>
			{ children }
		</LoadingContext.Provider>
	);
}

function useLoading() {
	const context = useContext( LoadingContext );

	if ( context === undefined ) {
		throw new Error( 'useLoading must be used within a LoadingProvider' );
	}

	return context;
}

export { LoadingProvider, useLoading };
