import type { Dayjs } from 'dayjs';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

type DateInput = Date | string | null;
type DateString = string | null;

type DateFormats = {
	input: string;
	outputDate: string;
	outputDatetime: string;
	outputDateString: string;
	outputTime: string;
	output12HrTimeWithTimezone: string;
	datePickerDate: string;
	datePickerDatetime: string;
};

type SplitDate = {
	year: string;
	month: string;
	day: string;
	time: string;
	amPm: string;
	timezone: string;
};

dayjs.extend( customParseFormat );
dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.extend( advancedFormat );

const dateFormat = process.env.NEXT_PUBLIC_DATE_FORMAT || 'us';

const dateFormatsMap: Record<string, DateFormats> = {
	eu: {
		input: 'YYYY-MM-DDTHH:mm:ss[Z]',
		outputDate: 'DD/MM/YYYY',
		outputDatetime: 'DD/MM/YYYY HH:mm',
		outputDateString: 'DD/MM/YYYY HH:mm:ss',
		outputTime: 'HH:mm',
		output12HrTimeWithTimezone: 'hh:mm A z',
		datePickerDate: 'dd/MM/yyyy',
		datePickerDatetime: 'dd/MM/yyyy hh:mm aa'
	},
	us: {
		input: 'YYYY-MM-DDTHH:mm:ss[Z]',
		outputDate: 'MM/DD/YYYY',
		outputDatetime: 'MM/DD/YYYY HH:mm',
		outputDateString: 'MM/DD/YYYY HH:mm:ss',
		outputTime: 'HH:mm',
		output12HrTimeWithTimezone: 'hh:mm A z',
		datePickerDate: 'MM/dd/yyyy',
		datePickerDatetime: 'MM/dd/yyyy hh:mm aa'
	}
};

export const defaultDateFormats = dateFormatsMap[ dateFormat ];

function isDate( date: Date | string ) {
	return Object.prototype.toString.call( date ) === '[object Date]';
}

function localToUtc( day: Dayjs ) {
	return day.utc();
}

function utcToLocal( day: Dayjs ) {
	const tzOffset = ( new Date() ).getTimezoneOffset();

	return day.utcOffset( tzOffset * - 1 ).utc( true );
}

// UTC String => Local Date()
export function unserializeDateField( dateStringObject: Date | string | null, inputFormat = defaultDateFormats.input ): Date | null {
	if ( !dateStringObject ) {
		return null;
	}

	if ( isDate( dateStringObject ) ) {
		return dateStringObject as Date;
	}

	return utcToLocal( dayjs( dateStringObject, inputFormat ) ).toDate();
}

// Local Date() => UTC String
export function serializeDateField( dateObj: DateInput, inputFormat = defaultDateFormats.input ): string {
	return dateObj ? localToUtc( dayjs( dateObj ) ).format( inputFormat ) : '';
}

// Local Date() => Local String
export function formatDate( dateObj: DateInput ): string {
	return dateObj ? dayjs( dateObj ).format( defaultDateFormats.outputDate ) : '';
}

// Local Date() => Local String
export function formatDatetime( dateObj: DateInput ): string {
	return dateObj ? dayjs( dateObj ).format( defaultDateFormats.outputDatetime ) : '';
}

// Local Date() => Local String
export function formatTime( dateObj: DateInput ): string {
	return dateObj ? dayjs( dateObj ).format( defaultDateFormats.outputTime ) : '';
}

// Local Date() => Local String
export function formatTimeWithTimezone( dateString: DateString ): string {
	return dateString ? dayjs( dateString ).format( defaultDateFormats.output12HrTimeWithTimezone ) : '';
}

// => Local Timestamp
export function getCurrentTimestamp(): number {
	return dayjs().unix();
}

// Local String => Local Timestamp
export function getDateTimestamp( dateString: string ): number | null {
	return dateString ? dayjs( dateString, defaultDateFormats.input ).unix() : null;
}

// => Local String
export function getCurrentDateString( dateFormat: string = defaultDateFormats.outputDateString ): string {
	return dayjs().format( dateFormat );
}

// => Local String
export function getCurrentYear(): number {
	return dayjs().year();
}

// UTC String => Local String
export function getSplitDate( dateStr?: string ): SplitDate {
	if ( !dateStr ) {
		return {
			month: '',
			day: '',
			year: '',
			time: '',
			amPm: '',
			timezone: ''
		};
	}

	const dateObject = unserializeDateField( dateStr );
	const dayjsObject = dayjs( dateObject );

	return {
		month: dayjsObject.format( 'MM' ),
		day: dayjsObject.format( 'DD' ),
		year: dayjsObject.format( 'YYYY' ),
		time: dayjsObject.format( 'hh:mm' ),
		amPm: dayjsObject.format( 'a' ),
		timezone: dayjsObject.format( 'z' )
	};
}

// MM/YYYY => Local String
export function getLastDayOfMonth( month: number, year: number ): string {
	const lastDayObject = new Date( year, month + 1, 0 );
	
	return dayjs( lastDayObject ).format( 'DD' );
}

// Local Date() => Local Date()
export function getEndOfDay( dateObj: DateInput ): Date | null {
	return dateObj ? dayjs( dateObj ).endOf( 'day' ).toDate() : null;
}

// Local Date() => Local Date()
export function getStartOfDay( dateObj: DateInput ): Date | null {
	return dateObj ? dayjs( dateObj ).startOf( 'day' ).toDate() : null;
}
