type LanguageObject = {
	key: string;
	label: string;
};

export const languages: LanguageObject[] = [
	{
		key: 'en',
		label: 'English'
	},
	{
		key: 'zh',
		label: '中国'
	},
	{
		key: 'es',
		label: 'Español'
	},
	{
		key: 'pt',
		label: 'Português'
	},
	{
		key: 'ms',
		label: 'Melayu'
	},
	{
		key: 'ar',
		label: 'Arabic'
	}
];
