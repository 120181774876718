import { useMemo } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useIntl } from 'react-intl';

import { sharedText } from '@/translations/shared';

type PageTitleProps = {
	value?: string;
};

const bugherdKey = process.env.NEXT_PUBLIC_BUGHERD_KEY || '';

export default function PageTitle({
	value
}: PageTitleProps ) {
	const intl = useIntl();

	const titleValue = useMemo( () => {
		return [ value, intl.formatMessage( sharedText.siteTitle ) ].filter( a => a ).join( ' | ' );
	}, [ intl, value ] );

	return (
		<>
			<Head>
				<title>{ titleValue }</title>

				<meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover" />
				<meta property="og:title" content={ titleValue } />

				<meta name="theme-color" content="#0088cc" />
				<meta name="description" content="The Online Chain of Custody System provided by Omega Laboratories" />

				<link rel="apple-touch-icon" href="/icons/apple-touch-icon.png"/>

				<link rel="manifest" href="/app.webmanifest" />
			</Head>

			{ bugherdKey && (
				<Script
					src={ `https://www.bugherd.com/sidebarv2.js?apikey=${ bugherdKey }` }
					strategy="lazyOnload"
				/>
			)}
		</>
	);
}
