import type { PropsWithChildren } from 'react';
import type { RxCollection } from 'rxdb';
import type { LookupValue } from '@/types/collection';

import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { useRxCollection, useRxQuery } from 'rxdb-hooks';

import { useLoading } from '@/hooks/useLoading';
import { checkHeartbeat } from '@/services/connectionStatus';
import { loadApplicationSettings } from '@/services/localDatabase';

type ApplicationSettingsProviderProps = PropsWithChildren;

type ApplicationSettingsContextProps = {
	ccfApplicationSettings: any;
	testReasons: {
		id: number;
		description: string;
		isDefault: boolean;
		name: string;
		sortOrder: number;
	}[];
	hairLocationLookupValues: LookupValue[];
	anonymousTestLookupValues: LookupValue[];
	languageLookupValues: LookupValue[];
	yesNoLookupValues: LookupValue[];
	testSubprocessResultLookupValues: LookupValue[];
	donorSexLookupValues: LookupValue[];
	hairTypeLookupValues: LookupValue[];
	sampleTypes: any[];
	splitTypeValues: LookupValue[];
	overallResultTypes: LookupValue[];
	typesOfTest: LookupValue[];
	testingAuthorities: LookupValue[];
};

const emptyReturn = {
	ccfApplicationSettings: {},
	testReasons: [],
	hairLocationLookupValues: [],
	anonymousTestLookupValues: [],
	languageLookupValues: [],
	yesNoLookupValues: [],
	testSubprocessResultLookupValues: [],
	donorSexLookupValues: [],
	hairTypeLookupValues: [],
	sampleTypes: [],
	splitTypeValues: [],
	overallResultTypes: [],
	typesOfTest: [],
	testingAuthorities: []
};

const ApplicationSettingsContext = createContext<ApplicationSettingsContextProps | undefined>( undefined );

function ApplicationSettingsProvider({ children }: ApplicationSettingsProviderProps ) {
	const initiallyLoaded = useRef( false );

	const { openModal, closeModal } = useLoading();

	const collection = useRxCollection( 'applicationsettings' );

	const query = useMemo( () => {
		if ( !collection ) {
			return null;
		}

		return collection.findOne();
	}, [ collection ] );

	// @ts-ignore
	const { result: settings }: { result: any } = useRxQuery( query, { json: true } );

	const data = useMemo<ApplicationSettingsContextProps>( () => {
		if ( !settings[ 0 ] ) {
			return emptyReturn;
		}

		const {
			ccfApplicationSettings,
			testReasons,
			hairLocationLookupValues,
			anonymousTestLookupValues,
			languageLookupValues,
			yesNoLookupValues,
			testSubprocessResultLookupValues,
			donorSexLookupValues,
			hairTypeLookupValues,
			sampleTypes,
			splitTypeValues,
			overallResultTypes,
			typesOfTest,
			testingAuthorities
		} = settings[ 0 ];

		return {
			ccfApplicationSettings: JSON.parse( ccfApplicationSettings ),
			testReasons: JSON.parse( testReasons ),
			hairLocationLookupValues: JSON.parse( hairLocationLookupValues ),
			anonymousTestLookupValues: JSON.parse( anonymousTestLookupValues ),
			languageLookupValues: JSON.parse( languageLookupValues ),
			yesNoLookupValues: JSON.parse( yesNoLookupValues ),
			testSubprocessResultLookupValues: JSON.parse( testSubprocessResultLookupValues ),
			donorSexLookupValues: JSON.parse( donorSexLookupValues ),
			hairTypeLookupValues: JSON.parse( hairTypeLookupValues ),
			sampleTypes: JSON.parse( sampleTypes ),
			splitTypeValues: JSON.parse( splitTypeValues || '[]' ),
			overallResultTypes: JSON.parse( overallResultTypes ),
			typesOfTest: JSON.parse( typesOfTest || '[]' ),
			testingAuthorities: JSON.parse( testingAuthorities || '[]' )
		};
	}, [ settings ] );

	useEffect( () => {
		async function loadApplicationDetails( collection: RxCollection ) {
			initiallyLoaded.current = true;

			try {
				openModal({
					modalId: 'app-settings',
					modalText: 'Loading application settings'
				});

				await checkHeartbeat();
				await loadApplicationSettings( collection );
			} catch {
				console.info( 'Application settings not being loaded due to lack of connection' );
			} finally {
				closeModal( 'app-settings' );
			}
		}

		if ( initiallyLoaded.current || !collection ) {
			return;
		}

		loadApplicationDetails( collection );
	}, [ collection, openModal, closeModal ] );

	return (
		<ApplicationSettingsContext.Provider value={ data }>
			{ children }
		</ApplicationSettingsContext.Provider>
	);
}

function useApplicationSettings() {
	const context = useContext( ApplicationSettingsContext );

	if ( context === undefined ) {
		throw new Error( 'useApplicationSettings must be used within a ApplicationSettingsProvider' );
	}

	return context;
}

export { ApplicationSettingsProvider, useApplicationSettings };
