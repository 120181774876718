import type { RxDatabase } from 'rxdb';
import type { AppPropsWithLayout } from '@/types/global';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { config } from '@fortawesome/fontawesome-svg-core';

import Translated from '@/components/App/Translated';

import { LoadingProvider } from '@/hooks/useLoading';
import { OfflineStatusProvider } from '@/hooks/useOfflineStatus';
import { RepinProvider } from '@/hooks/useRepin';
import { TranslationProvider } from '@/hooks/useTranslation';
import { checkHeartbeat } from '@/services/connectionStatus';
import { initialize as initializeCollections } from '@/stores/db/initializeCollections';
import { initializeTranslations } from '@/stores/db/initializeTranslations';
import { queryClient, persister } from '@/stores/query/persister';

import '@fortawesome/fontawesome-svg-core/styles.css';
import '@/styles/globals.css';

config.autoAddCss = false;

function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout ) {
	const { pathname } = useRouter();

	const [ translationsDb, setTranslationsDb ] = useState<RxDatabase | any>();
	const [ collectionsDb, setCollectionsDb ] = useState<RxDatabase | any>();
	const [ refetchInterval, setRefetchInterval ] = useState<number>( 0 );

	useEffect( () => {
		initializeCollections().then( setCollectionsDb );
		initializeTranslations().then( setTranslationsDb );
	}, [] );

	// Turn off session refetch on signin page.
	useEffect( () => {
		async function connectionCheck() {
			await checkHeartbeat();
		}

		if ( pathname && !pathname.includes( '/auth/signin' ) ) {
			setRefetchInterval( 75 );
		}

		connectionCheck();
	}, [ pathname ] );

	if ( !translationsDb ) {
		return null;
	}

	return (
		<RepinProvider>
			<OfflineStatusProvider>
				<SessionProvider session={ session } refetchInterval={ refetchInterval }>
					<PersistQueryClientProvider
						client={ queryClient }
						persistOptions={{ persister }}
					>
						<LoadingProvider>
							<TranslationProvider db={ translationsDb || {} }>
								<Translated
									Component={ Component }
									collectionsDb={ collectionsDb }
									pageProps={ pageProps }
									session={ session }
								/>
							</TranslationProvider>
						</LoadingProvider>
					</PersistQueryClientProvider>
				</SessionProvider>
			</OfflineStatusProvider>
		</RepinProvider>
	);
}

export default App;
