type ErrorObject = {
	code: string;
	translationKey: string;
	message: string;
};

export const saveErrors: Record<number, ErrorObject> = {
	1: {
		code: 'SaveSuccessful',
		translationKey: 'CcfPwa_CollectionSavedSuccessfully',
		message: 'Collection saved successfully'
	},
	2: {
		code: 'SpecimenIdInUse',
		translationKey: 'NewCcf_SaveError_2',
		message: 'This Specimen ID is already being used'
	},
	3: {
		code: 'AuthenticationFailed',
		translationKey: 'CcfPwa_AuthenticationFailed',
		message: 'Authentication failed'
	},
	4: {
		code: 'SpecimenIdTooLong',
		translationKey: 'NewCcf_SaveError_4',
		message: 'Specimen ID must be 15 characters max.'
	},
	5: {
		code: 'NotesTooLong',
		translationKey: 'NewCcf_SaveError_5',
		message: 'Collection Notes exceeds maximum allowed.'
	},
	6: {
		code: 'SpecimenIdEmpty',
		translationKey: 'NewCcf_SaveError_6',
		message: 'Specimen ID is missing.'
	},
	7: {
		code: 'ValidationPassed',
		translationKey: 'CcfPwa_ValidationPassed',
		message: 'Validation passed'
	},
	8: {
		code: 'TestRequestAlreadyCancelled',
		translationKey: 'CcfPwa_TestRequestAlreadyCancelled',
		message: 'Test Request already cancelled'
	},
	9: {
		code: 'TestRequestAlreadyCompleted',
		translationKey: 'CcfPwa_TestRequestAlreadyCancelled',
		message: 'Test Request already completed'
	},
	10: {
		code: 'LaboratoryNotSet',
		translationKey: 'NewCcf_SaveError_10',
		message: 'Laboratory is missing.'
	},
	11: {
		code: 'PocOverallResultNotSet',
		translationKey: 'NewCcf_SaveError_11',
		message: 'Overall result is missing for POCT.'
	},
	12: {
		code: 'PocOverallResultMustBePositiveCheckFailed',
		translationKey: 'NewCcf_SaveError_12',
		message: 'Overall result must be positive.'
	},
	13: {
		code: 'SubprocessesMustHaveResults',
		translationKey: 'NewCcf_SaveError_13',
		message: 'Missing a result for POCT.'
	},
	14: {
		code: 'CollectionDateIsRequired',
		translationKey: 'NewCcf_SaveError_14',
		message: 'Collection date is missing.'
	},
	15: {
		code: 'DonorDeclarationRequired',
		translationKey: 'NewCcf_SaveError_15',
		message: 'Donor declaration is missing.'
	},
	16: {
		code: 'TestReasonIsEmpty',
		translationKey: 'NewCcf_SaveError_16',
		message: 'Reason for test is missing.'
	},
	17: {
		code: 'RequestingAgencyIsEmpty',
		translationKey: 'NewCcf_SaveError_17',
		message: 'Requesting agency is missing.'
	},
	18: {
		code: 'CollectionSiteIsEmpty',
		translationKey: 'NewCcf_SaveError_18',
		message: 'Collection site is missing.'
	},
	19: {
		code: 'TestTypeIsEmpty',
		translationKey: 'NewCcf_SaveError_19',
		message: 'Test performed is missing.'
	}
};
