import { API_BASE } from '@/services/webservices';
import { service as connectionService } from '@/stores/xstate/offline/offlineMachine';

const HEARTBEAT_TIMEOUT = Number( process.env.NEXT_PUBLIC_HEARTBEAT_MAX_REQUEST_TIMEOUT ) || 5000;

function fetchWithTimeout( url: string, timeoutMs: number, { signal, ...options }: any ) {
	const controller = new AbortController();

	const promise = fetch( url, { signal: controller.signal, ...options } );

	if ( signal ) {
		signal.addEventListener( 'abort', () => controller.abort(), timeoutMs );
	}

	const timeout = setTimeout( () => controller.abort(), timeoutMs );

	return promise.finally( () => clearTimeout( timeout ) );
}

export async function checkHeartbeat() {
	try {
		await fetchWithTimeout( `${ API_BASE }/initialize/heartbeat`, HEARTBEAT_TIMEOUT, {
			method: 'get',
			headers: {
				'Content-Type': 'application/json'
			}
		});

		connectionService.send({ type: 'CONNECTION_ACTIVE' });

		return Promise.resolve();
	} catch( e ) {
		connectionService.send({ type: 'CONNECTION_INACTIVE' });

		return Promise.reject( e );
	}
}
