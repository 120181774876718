import type { CollectionExport } from '@/types/global';

type UserData = {
	userId: string;
	preferredLanguage: string;
	collectionSiteId: string;
};

export const userData: CollectionExport<UserData> = {
	schema: {
		title: 'userdata',
		version: 1,
		primaryKey: 'userId',
		type: 'object',
		properties: {
			userId: {
				type: 'string',
				maxLength: 30
			},
			preferredLanguage: {
				type: 'string'
			},
			collectionSiteId: {
				type: 'string'
			}
		}
	}
};
