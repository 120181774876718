import type { MouseEventHandler, ReactNode } from 'react';
import type { IconGlyph } from '@/components/Icon';

import Link from 'next/link';
import cn from 'classnames';

import Icon from '@/components/Icon';

type ButtonProps = {
	children: ReactNode;
	variant?: 'primary' | 'outline' | 'tertiary' | 'dark' | 'text';
	size?: 'small' | 'medium' | 'large' | 'x-large' | 'text-medium' | 'inline';
	type?: 'button' | 'submit' | 'reset';
	icon?: IconGlyph;
	onClick?: MouseEventHandler;
	href?: string;
	fullWidth?: boolean;
	useNext?: boolean;
	disabled?: boolean;
};

const baseClasses = [ 'items-center', 'font-bold', 'rounded', 'whitespace-nowrap' ];

const variantMap: { [ key: string ]: string } = {
	'primary': 'text-white border border-primary-400 bg-gradient-to-b from-primary-200 to-primary-400',
	'outline': 'text-primary-400 bg-white border border-primary-400 hover:bg-gray-100 transition-all',
	'tertiary': 'text-white bg-gradient-to-b from-green-500 to-green-600 border border-green-600',
	'dark': 'text-white bg-gradient-to-b from-gray-700 to-gray-800 border border-gray-800',
	'text': 'text-primary-400 bg-transparent'
};

const sizeMap: { [ key: string ]: string } = {
	'small': 'py-0.5 px-2 text-xs',
	'medium': 'py-1 px-4 text-sm',
	'large': 'py-2 px-6 text-base',
	'x-large': 'py-3 px-9 text-lg',
	'text-medium': 'p-0.5 text-sm',
	'inline': 'text-sm'
};

export default function Button({
	children,
	variant = 'primary',
	size = 'medium',
	type = 'button',
	onClick,
	icon,
	href,
	fullWidth,
	useNext = true,
	disabled = false
} : ButtonProps ) {
	const className = cn(
		baseClasses,
		variantMap[ variant ],
		sizeMap[ size ],
		{ 'opacity-40': disabled },
		{ 'block w-full text-center': fullWidth },
		{ 'inline-flex': !fullWidth }
	);

	if ( href ) {
		if ( disabled ) {
			return (
				<span className={ className }>{ children }</span>
			);
		}

		if ( useNext ) {
			return (
				<Link href={ href } className={ className }>
					{ children }
				</Link>
			);
		}

		return (
			<a className={ className } href={ href }>{ children }</a>
		);
	}

	return (
		<button type={ type } disabled={ disabled } className={ className } onClick={ onClick }>
			{ children }

			{ icon && <span className="inline-block ml-2"><Icon glyph={ icon } /></span> }
		</button>
	);
}
