import type { CollectionExport } from '@/types/global';

type CredentialsStore = {
	password: string;
	username: string;
	collectionSiteId: string;
	jwt: string;
	rt: string;
	iterations: number;
};

export const tempCredentials: CollectionExport<CredentialsStore> = {
	schema: {
		title: 'tmpstorecreds',
		version: 2,
		primaryKey: 'username',
		type: 'object',
		properties: {
			username: {
				type: 'string',
				maxLength: 50
			},
			password: {
				type: 'string'
			},
			collectionSiteId: {
				type: 'string'
			},
			jwt: {
				type: 'string'
			},
			rt: {
				type: 'string'
			},
			iterations: {
				type: 'number'
			}
		},
		encrypted: [ 'password', 'jwt', 'rt', 'iterations' ]
	},
	migrationStrategies: {
		2: function( oldDoc ) {
			return oldDoc;
		}
	}
};
