import type { CollectionExport } from '@/types/global';

type RemoteCollection = {
	collectionId: string;
	collectionObject: string;
	collectionSiteId: string;
	isSynced: boolean;
	collectionDate: number,
	updatedAt: number;
	status: string;
};

export const remotecollections: CollectionExport<RemoteCollection> = {
	schema: {
		title: 'remotecollections',
		version: 8,
		primaryKey: 'collectionId',
		type: 'object',
		properties: {
			collectionId: {
				type: 'string',
				maxLength: 30
			},
			collectionObject: {
				type: 'string'
			},
			collectionSiteId: {
				type: 'string'
			},
			isSynced: {
				type: 'boolean'
			},
			collectionDate: {
				type: 'datetime'
			},
			updatedAt: {
				type: 'datetime'
			},
			status: {
				type: 'string'
			}
		},
		encrypted: [ 'collectionObject' ]
	},
	migrationStrategies: {
		8: function( oldDoc ) {
			return oldDoc;
		}
	}
};
