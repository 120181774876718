import { QueryClient } from '@tanstack/react-query';
import type { PersistedClient, Persister } from '@tanstack/react-query-persist-client';

import { get, set, del } from 'idb-keyval';

export function createIDBPersister( idbValidKey: IDBValidKey = 'reactQuery' ) {
	return {
		persistClient: async ( client: PersistedClient ) => {
			set( idbValidKey, client );
		},

		restoreClient: async () => {
			return get<PersistedClient>( idbValidKey );
		},

		removeClient: async () => {
			await del( idbValidKey );
		}
	} as Persister;
}

export const persister = createIDBPersister();
export const queryClient = new QueryClient();
