import type { CollectionExport } from '@/types/global';

type CollectionSiteSettings = {
	clientLogos: string;
	collectionSiteId: string;
	collectionSite: string;
	lastUpdateTime: string;
	requestingAgencies: string;
	testSubprocesses: string;
	testSubstanceResults: string;
	testTemplates: string;
};

export const collectionSiteSettings: CollectionExport<CollectionSiteSettings> = {
	schema: {
		title: 'collectionsitesettings',
		version: 6,
		primaryKey: 'collectionSiteId',
		type: 'object',
		properties: {
			clientLogos: {
				type: 'string'
			},
			collectionSiteId: {
				type: 'string',
				maxLength: 30
			},
			collectionSite: {
				type: 'string'
			},
			lastUpdateTime: {
				type: 'string'
			},
			requestingAgencies: {
				type: 'string'
			},
			testSubprocesses: {
				type: 'string'
			},
			testSubstanceResults: {
				type: 'string'
			},
			testTemplates: {
				type: 'string'
			}
		}
	},
	migrationStrategies: {
		4: function( oldDoc ) {
			return oldDoc;
		},
		5: function( oldDoc ) {
			if ( !oldDoc.clientLogos ) {
				oldDoc.clientLogos = '[]';
			}

			return oldDoc;
		},
		6: function( oldDoc ) {
			oldDoc.lastUpdateTime = '';

			return oldDoc;
		}
	}
};
