import { useEffect } from 'react';
import { setUser } from '@sentry/nextjs';

type SentryManagerProps = {
	session: any;
};

export default function SentryManager({
	session
}: SentryManagerProps ) {
	useEffect( () => {
		if ( session ) {
			setUser({
				username: `${ session?.user?.firstName } ${ session?.user?.lastName }`,
				id: session?.user?.id
			});
		} else {
			setUser( null );
		}

		return () => {
			setUser( null );
		};
	}, [ session ] );

	return null;
}
