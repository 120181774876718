import type { Collection } from '@/types/collection';

import get from 'lodash/get';

export type SavingParams = {
	checkCredentials?: boolean;
	finishCollection?: boolean;
	savingDraft?: boolean;
	skipTestResultValidation?: boolean;
	userPassword?: null | string;
	isDonorWithdrawClicked?: boolean;
};

export type Route = {
	stepOrder: number;
	key: string;
	urlHash: string;
	componentKey: string;
	savingParams?: SavingParams;
	continueButtonKey?: string;
};

export function getSavingParams( stepName?: string, context?: Collection ): SavingParams {
	let returnParams: SavingParams = {};

	const defaultParams: SavingParams = {
		checkCredentials: false,
		finishCollection: false,
		savingDraft: true,
		skipTestResultValidation: true,
		userPassword: null
	};

	if ( !stepName ) {
		returnParams = { ...defaultParams };
	} else {
		const matchedRoute = routes[ stepName ];

		if ( matchedRoute?.savingParams ) {
			returnParams = { ...defaultParams, ...matchedRoute.savingParams };
		}
	}

	if ( returnParams.finishCollection || returnParams.checkCredentials ) {
		returnParams = {
			...returnParams,
			userPassword: context?.meta.collectorPwd
		};
	}

	return returnParams;
}

export const routes: Record<string, Route> = {
	requestingAgency: {
		stepOrder: 1,
		key: 'requestingAgency',
		urlHash: 'requesting-agency',
		componentKey: 'RequestingAgency'
	},
	sampleTypes: {
		stepOrder: 2,
		key: 'sampleTypes',
		urlHash: 'sample-types',
		componentKey: 'SampleTypes'
	},
	donorInfo: {
		stepOrder: 3,
		key: 'donorInfo',
		urlHash: 'donor-info',
		componentKey: 'DonorInfo'
	},
	lotDetails: {
		stepOrder: 4,
		key: 'lotDetails',
		urlHash: 'lot-details',
		componentKey: 'LotDetails'
	},
	specimenDetails: {
		stepOrder: 5,
		key: 'specimenDetails',
		urlHash: 'specimen-details',
		componentKey: 'SpecimenDetails'
	},
	collectorDetails: {
		stepOrder: 6,
		key: 'collectorDetails',
		urlHash: 'collector-details',
		componentKey: 'CollectorDetails'
	},
	donorSignature: {
		stepOrder: 7,
		key: 'donorSignature',
		urlHash: 'donor-signature',
		componentKey: 'DonorSignature',
		continueButtonKey: 'continueToResults'
	},
	poctResults: {
		stepOrder: 8,
		key: 'poctResults',
		urlHash: 'poct-results',
		componentKey: 'PoctResults',
		savingParams: {
			finishCollection: true,
			savingDraft: false,
			skipTestResultValidation: false
		}
	},
	sendToLaboratory: {
		stepOrder: 9,
		key: 'sendToLaboratory',
		urlHash: 'send-to-lab',
		componentKey: 'SendToLab'
	},
	printSummary: {
		stepOrder: 10,
		key: 'printSummary',
		urlHash: 'print-summary',
		componentKey: 'PrintSummary'
	},
	ccfSummary: {
		stepOrder: 11,
		key: 'ccfSummary',
		urlHash: 'ccf-summary',
		componentKey: 'CcfSummary'
	},
	withdrawalComplete: {
		stepOrder: 12,
		key: 'withdrawalComplete',
		urlHash: 'withdrawal-complete',
		componentKey: 'WithdrawalComplete'
	},
	finalize: {
		stepOrder: 13,
		key: 'finalize',
		urlHash: '',
		componentKey: '',
		savingParams: {
			finishCollection: true,
			savingDraft: false,
			skipTestResultValidation: false
		}
	}
};

export function getRouteByUrl( url?: string ): undefined | any {
	if ( !url ) {
		return undefined;
	}

	const matchedEntry = Object.entries( routes ).find( ( [ , val ] ) => {
		return val.urlHash === url;
	});

	if ( matchedEntry ) {
		return matchedEntry[ 1 ];
	} else {
		return undefined;
	}
}

export function routeArray(): Route[] {
	return Object.entries( routes ).map( ( [ key, val ] ) => ( val ) );
}

export function getSortedStepKeys() {
	return Object.entries( routes )
		.sort( ( [, a ], [, b ] ) => {
			return a.stepOrder - b.stepOrder;
		})
		.map( ( [ , val ] ) => {
			return val.key;
		});
}

export function getRouteValue( state: any, valueKey: keyof Route, defaultValue: unknown ) {
	let stateName = '';

	if ( typeof state.value === 'object' ) {
		stateName = Object.keys( state.value )[ 0 ];
	} else if ( typeof state.value === 'string' ) {
		stateName = state.value;
	}

	return get( routes[ stateName ], valueKey, defaultValue );
}
